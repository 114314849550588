// <upload var="lease.customFields.sitePlan"/>

angular.module("LeasePilot").directive("leaseUpload", [
  function() {
    const leaseUploadLink = function(scope, element, attrs) {
      scope.uploads = [];

      scope.$watch("name", function(newval) {
        scope.uploads = newval?.images;
      });
    };

    return {
      restrict: "E",
      scope: {
        name: "=",
        isChanging: "="
      },
      template: `<div ng-repeat='upload in uploads track by $index' style='text-align: center' class='center-block' ng-class='{ adding: isChanging, added: !isChanging}'><img ng-src='{{ upload }}' style='max-width: 100%;'/></div>`,
      link: leaseUploadLink
    };
  }
]);
